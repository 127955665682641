export default {
    attention : "Attention",
    construction : "Web site in construction. Information presented may not be complete or accurate.",
    products : "Products",
    location : "Location",
    contacts : "Contacts",
    schedule : "Opening hours",
    monday : "Monday",
    tuesday : "Tuesday",
    wednesday : "Wednesday",
    thursday : "Thursday",
    friday : "Friday",
    saturday : "Saturday",
    sunday : "Sunday",
    "7pmTo2am" : "19:00 to 02:00",
    "6h30pmTo2am" : "18:30 to 02:00",
    "longerSchedule" : "08:00 to 10:00 and from 19:00 to 02:00",
    "404error" : "404 Page not found",
    allRightsReserved : "All rights reserved."
}