import { FaFacebook } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import contacts_data from "./contacts_data";

export default function Contacts() {

    const { t } = useTranslation();

    return <>
        <div id="contacts">
            <label className="pageTitle" >{ t("contacts") }</label>
            <br></br>
            <div>
                <div>
                    <a href={`tel:${contacts_data.phone}`}>
                        <FaPhone/> { _normalizePhoneNumber() }
                    </a>
                </div>
                <div>
                    <a href={`mailto:${contacts_data.email}`}>
                        <MdEmail/> { contacts_data.email }
                    </a>
                </div>
                <div>
                    <a href={contacts_data.facebook}>
                        <FaFacebook/> Facebook
                    </a>
                </div>
            </div>
        </div>
    </>
}

function _normalizePhoneNumber() {
    return contacts_data.phone.replace(/\D(\d{3})(\d{3})(\d{3})(\d{3})/, "(+$1) $2 $3 $4");
}

