export default [
    {
        name: "Pão",
        imageUrl: null
    },
    {
        name: "Papo-seco",
        imageUrl: null
    },
    {
        name: "Pão de Mistura",
        imageUrl: null
    },
    {
        name: "Pão de trigo tipo caseiro",
        imageUrl: null
    },
    {
        name: "Pão de sementes",
        imageUrl : null,
        varieties: ["Pequeno", "Médio", "Grande"]
    },
    {
        name: "Massa Sovada",
        imageUrl: process.env.PUBLIC_URL + "/massa_sovada.jpg",
        varieties: ["700g", "900g"]
    },
    {
        name: "Biscoitos",
        imageUrl: null,
        varieties: ["250g", "500g"]
    },
    {
        name: "Massa Sovada com choco até 2 x 100g",
        imageUrl: null,
    },
];