import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoIosPrint } from "react-icons/io";


const SCHEDULE = [
    { weekday : "monday", hours : "7pmTo2am"},
    { weekday : "tuesday", hours : "6h30pmTo2am"},
    { weekday : "wednesday", hours : "longerSchedule"},
    { weekday : "thursday", hours : "6h30pmTo2am"},
    { weekday : "friday", hours : "longerSchedule"},
    { weekday : "saturday", hours : "longerSchedule"},
    { weekday : "sunday", hours : "6h30pmTo2am"},
]

export default function Schedule() {

    const { t } = useTranslation();

    return <>
        <label className="pageTitle">{ t("schedule") }</label>
        <div style={{display : "block"}}>
            <div style={{
                textAlign : "start", 
                marginLeft : 30, 
                display: "grid", 
                width : "min(100vw, 1000px)",
                fontSize : 24
            }} 
            className="schedule"
            >
                {
                    SCHEDULE.map((s, i) => <>
                        <div 
                            style={{
                                gridColumnStart : 0, 
                                gridRowStart : i + 1, 
                                width : "min(24vw, 160px)"
                                
                        }}
                        className="schedule-weekday"
                        >
                            <b>{ t(s.weekday) }</b>
                        </div>
                        <div 
                            style={{gridColumnStart : 2, gridRowStart : i + 1, width : "30vw", marginBottom : 15}}
                            className="schedule-hours"
                        >
                            { t(s.hours) }
                        </div>
                    </>)
                }
            <Button className="d-print-none" style={{
                width : 150, 
                backgroundColor : "rgb(122, 28, 62)",
                borderColor : "rgb(122, 28, 62)"
            }} 
            onClick={() => {window.print()}}
            
            ><IoIosPrint/>Imprimir</Button>
            </div>
        </div>
    </>
}