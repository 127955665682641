import { env } from "process";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import { Carousel } from "react-bootstrap";

export default function Home() {
  const { t } = useTranslation();

  return <div id="home" style={{ marginLeft: 50, marginTop: 25 }}>
    <div style={{ width: "min(80vw, 600px)", textAlign: "left" }}>
      Fundada em Outubro de 2012 por Manuel José Mateus, a Paniconfiança é a única padaria da
      freguesia dos Mosteiros, e é regionalmente famosa pela sua massa Sovada.
    </div>

    {/* <Carousel>
      <Carousel.Item>
        <img
          src={process.env.PUBLIC_URL + "/multiplos_produtos.jpg"}
          width="60%"
        />
        </Carousel.Item>
    </Carousel> */}
  </div>
}