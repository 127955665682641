import { useTranslation } from "react-i18next"

import { MdOutlineWebAssetOff } from "react-icons/md";


export default function NotFoundPage() {
    const { t } = useTranslation();

    return <>
        <label className="pageTitle">{ t("404error") }</label>
        <br/>
        <MdOutlineWebAssetOff size={256}/>
    </>
}