export default {
    attention : "Atenção",
    construction : "Web site em construção. As informações apresentadas podem não estar completas ou precisas.",
    products : "Produtos",
    location : "Localização",
    contacts : "Contactos",
    schedule : "Horário de funcionamento",
    monday : "Segunda-feira",
    tuesday : "Terça-feira",
    wednesday : "Quarta-feira",
    thursday : "Quinta-feira",
    friday : "Sexta-feira",
    saturday : "Sábado",
    sunday : "Domingo",
    "7pmTo2am" : "19:00 às 02:00",
    "6h30pmTo2am" : "18:30 às 02:00",
    "longerSchedule" : "08:00 às 10:00 e das 19:00 às 02:00",
    "404error" : "404 Página não encontrada",
    allRightsReserved : "Todos os Direitos Reservados."
}