export default {
    attention : "Aufmerksamkeit",
    construction : "Website im Aufbau. Die bereitgestellten Informationen sind möglicherweise nicht vollständig oder korrekt.",
    products : "Produkte",
    location : "Standort",
    contacts : "Kontakte",
    schedule : "Öffnungszeiten",
    monday : "Montag",
    tuesday : "Dienstag",
    wednesday : "Mittwoch",
    thursday : "Donnerstag",
    friday : "Freitag",
    saturday : "Samstag",
    sunday : "Sonntag",
    "7pmTo2am" : "19:00 bis 02:00 Uhr",
    "6h30pmTo2am" : "18:30 bis 02:00 Uhr",
    "longerSchedule" : "08:00 bis 10:00 Uhr und von 19:00 bis 02:00 Uhr",
    "404error" : "404 Seite nicht gefunden",
    allRightsReserved : "Alle Rechte vorbehalten."
}