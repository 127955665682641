import { useTranslation } from "react-i18next";

export default function Footer() {
    const { t } = useTranslation();


    return <footer style={{
        paddingTop : 10, 
        paddingBottom : 10, 
        width : "100%", 
        height : 150,
        bottom : 0, 
        // height: "2.5rem",
        position : "absolute",
        color : "white"
    }} className="bg-paniconfiancaThemeTransparent d-print-none">
        <div style={{
            display : "flex", 
            justifyContent : "center", 
            alignItems : "center",
            marginBottom : 10,
            // mixBlendMode: "difference"
          }}>
            <img 
              src={process.env.PUBLIC_URL + "/marca_acores.png"}
              style={{
                aspectRatio : 1, 
                height : "max(min(5vw, 70px), 50px)",
                marginRight : 10 
              }}
            />
            <img 
              src={process.env.PUBLIC_URL + "/icon_acores.png"} 
              style={{
                height : "max(min(3vw, 40px), 30px)", 
                aspectRatio : 4.12, 
              }}
            />
          </div>
          <div >
            <img src={process.env.PUBLIC_URL + "/mosteiros.png"} 
                style={{aspectRatio : 11.82, height : "min(6vw, 30px)"}}
            />
          </div>
          Copyright © 2024 Paniconfiança. { t("allRightsReserved") }
    </footer>
}