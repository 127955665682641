export default {
    attention : "Attention",
    construction : "Web site en construction. Les informations présentées peuvent ne pas être complètes ou exactes.",
    products : "Produits",
    location : "Emplacement",
    contacts : "Contacts",
    schedule : "Horaires d'ouvertures",
    monday : "Lundi",
    tuesday : "Mardi",
    wednesday : "Mercredi",
    thursday : "Jeudi",
    friday : "Vendredi",
    saturday : "Samedi",
    sunday : "Dimanche",
    "7pmTo2am" : "19:00 à 02:00",
    "6h30pmTo2am" : "18:30 à 02:00",
    "longerSchedule" : "de 8h00 à 10h00 et de 19h00 à 02h00",
    "404error" : "404 Page non trouvée",
    allRightsReserved : "Tous droits réservés."

}