import { useTranslation } from "react-i18next";

export default function Location() {
    const { t } = useTranslation();

    return <>
        <label className="pageTitle">{ t("location") }</label>
        <br/>
        <div>
            Rua Nova, 24 E, Mosteiros, Ponta Delgada, Ilha de São Miguel
        </div>
        <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1574.3844553007511!2d-25.81707073261719!3d37.889086652469175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xb4331921ebfe6cd%3A0x88784bf0ce259723!2sPadaria%20Paniconfianca!5e0!3m2!1spt-PT!2spt!4v1706988253471!5m2!1spt-PT!2spt" 
            style={{
                width : "min(80vw, 800px)", 
                height : "min(100vw,500px)",
                border : 0
            }}
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"
        />    
    </>
}