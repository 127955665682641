export default {
    attention : "Atención",
    construction : "Web site en construccion. La información presentada puede no ser completa o precisa.",
    products : "Productos",
    location : "Ubicación",
    contacts : "Contactos",
    schedule : "Horas de oficina",
    monday : "Lunes",
    tuesday : "Martes",
    wednesday : "Miércoles",
    thursday : "Jueves",
    friday : "Viernes",
    saturday : "Sábado",
    sunday : "Domingo",
    "7pmTo2am" : "19:00 a 02:00",
    "6h30pmTo2am" : "18:30 a 02:00",
    "longerSchedule" : "08:00 a 10:00 y de 19:00 a 02:00",
    "404error" : "404 Pagina no encontrada",
    allRightsReserved : "Todos los derechos reservados."
}