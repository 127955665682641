
import i18n from "i18next";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { initReactI18next } from "react-i18next";
import Flag from 'react-world-flags';
import de from "./lang/de";
import en from './lang/en';
import es from './lang/es';
import fr from "./lang/fr";
import pt from './lang/pt';

export const SUPPORTED_LANGUAGES = [
    { name: "Português", url: process.env.PUBLIC_URL + "/", flag: () => <PortugueseFlag /> },
    { name: "English", url: process.env.PUBLIC_URL + "/en", flag: () => <EnglishFlag /> },
    { name: "Español", url: process.env.PUBLIC_URL + "/es", flag: () => <SpanishFlag /> },
    { name: "Français", url: process.env.PUBLIC_URL + "/fr", flag: () => <FrenchFlag /> },
    { name: "Deutsch", url: process.env.PUBLIC_URL + "/de", flag: () => <GermanFlag /> },
]

const PortugueseFlag = () =>
    <Flag code={"pt"} width={40} height={25} style={{ marginRight: 5 }} />

const EnglishFlag = () =>
    <Flag code={"gb"} width={40} height={25} style={{ marginRight: 5 }} />

const SpanishFlag = () =>
    <Flag code={"es"} width={40} height={25} style={{ marginRight: 5 }} />

const FrenchFlag = () =>
    <Flag code={"fr"} width={40} height={25} style={{ marginRight: 5 }} />

const GermanFlag = () =>
    <Flag code={"de"} width={40} height={25} style={{ marginRight: 5 }} />

export function urlLang() {
    let url = window.location.pathname.split("/")[1];

    if(url.length == 0)
        return ""

    if(SUPPORTED_LANGUAGES.some(l => l.url == url))
        return url;

    return undefined;
}

function urlToLanguageComponent() {
    let languageURL: string = window.location.pathname.split("/")[1];

    let language = SUPPORTED_LANGUAGES.find(l => l.url == languageURL)

    if (!language) {
        return <PortugueseFlag />
    }

    return <>{language.flag()}</>
}


function urlToOtherLanguagesDropdown() {
    let languageURL: string = window.location.pathname.split("/")[1];

    let otherLanguages = SUPPORTED_LANGUAGES.filter(l => l.url != languageURL)

    if (otherLanguages.length == SUPPORTED_LANGUAGES.length)
        otherLanguages = SUPPORTED_LANGUAGES.slice(1);

    return <>
        {
            otherLanguages.map(l => <>
                <NavDropdown.Item onClick={() => window.location.pathname = l.url}>
                    {l.flag()}
                    <label>{l.name}</label>
                </NavDropdown.Item>
            </>)
        }
    </>
}


export function LanguageDropdown() {
    return <NavDropdown title={
        <>
            {urlToLanguageComponent()}
        </>
    }>
        {urlToOtherLanguagesDropdown()}
    </NavDropdown>
}


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: {
                translation: en
            },
            pt: {
                translation: pt
            },
            es: {
                translation: es
            },
            fr : {
                translation : fr 
            },
            de : {
                translation : de 
            }
        },
        lng: urlLang(), // if you're using a language detector, do not define the lng option
        fallbackLng: "pt",
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

