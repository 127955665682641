import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';

import Contacts from './Contacts';
import Home from './Home';
import Location from './Location';
import PageLayout from './PageLayout';
import Products from './Products';
import Schedule from './Schedule';

import { urlLang } from './Language';
import NotFoundPage from "./NotFoundPage";
import * as Routing from "./Routing"

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path={Routing.BASE_URL} element={<PageLayout />}>
            <Route index element={<Home />} />
            <Route path={Routing.SCHEDULE} element={<Schedule />} />
            <Route path={Routing.PRODUCTS} element={<Products />} />
            <Route path={Routing.LOCATION} element={<Location />} />
            <Route path={Routing.CONTACTS} element={<Contacts />} />
            <Route path={Routing.PRODUCTS} element={<Products />} />
            <Route path="*" element={<NotFoundPage/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
