import { useTranslation } from "react-i18next";
import products_data from "./products_data";

const STOCK_PHOTO_URL = "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/Korb_mit_Br%C3%B6tchen.JPG/1200px-Korb_mit_Br%C3%B6tchen.JPG"


export default function Products() {

    const { t } = useTranslation();

    return <>
        <label className="pageTitle">{ t("products") }</label>
        <ul 
            style={{ 
                listStyleType: "none", 
                marginLeft: 30, 
                padding: 0, 
                textAlign : "start", 
                display : "flex",
                flexWrap : "wrap"
            }}
        >
            {
                products_data.map(product =>
                    <li 
                        className="bg-paniconfiancaThemeTransparent"
                        key={product.name} 
                        style={{
                            marginBottom : 20, 
                            marginRight : 20, 
                            display : "flex", 
                            alignItems : "center", 
                            width : 350,
                            borderRadius : 20,
                    }}>
                        <img 
                            src={product?.imageUrl ?? STOCK_PHOTO_URL} 
                            style={{
                                width : 150, 
                                height : 150, 
                                // backgroundColor : "black",
                                marginRight : 10,
                                // borderRadius : 20
                            }}
                        />
                        <div>
                            <div><b style={{color : "black"}}>{product.name}</b></div> 
                            {
                                product?.varieties?.map(v => <div style={{fontSize : 14, fontWeight : 500}}>{v}</div>) ?? <></>
                            }
                            {/* <label>{a.price.toFixed(2)} €</label> */}
                        </div>
                    </li>
                )
            }
            {/* <li>Folar (x.xx €)</li> */}
        </ul>
    </>
}