import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, Outlet } from 'react-router-dom';
import { MdConstruction } from "react-icons/md";

import { FaFacebook } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";

import { useTranslation } from "react-i18next";
import { LanguageDropdown } from './Language';
import { useEffect, useState } from 'react';
import Footer from './Footer';

import contacts_data from './contacts_data';

import * as Routing from "./Routing"


export default function PageLayout() {
    const { t } = useTranslation();

    return <div style={{position : "relative", minHeight : "100vh"}}>
        <div style={{
            background : `url(${process.env.PUBLIC_URL + "/mosteiros_wallpaper.jpg"})`,
            width : "100%",
            minHeight : "100%",
            position : "absolute",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition : "center",
            // backgroundAttachment: "fixed",
            filter: "contrast(40%) saturate(75%)",
            zIndex : -1
        }}  className='d-print-none'/>
        <div style={{backgroundColor : "yellow"}} className='d-print-none'>
            <MdConstruction/> <b>{ t("attention") }:</b> {t("construction")}
        </div>
        <Navbar 
            expand="lg" 
            className="bg-body-tertiary" 
            bg="paniconfiancaThemeTransparent" 
            // onToggle={(a) => setHamburgerExpanded(a)} 
            // onResize={() => console.log("HOOO")}
        >
            <Container>
                <Navbar.Brand>
                    <Link to={Routing.BASE_URL} style={{ color: "white" }}>
                        {/* <label style={{fontFamily : "Swiss 911 Std Ultra Compressed"}}>
                            Paniconfiança
                        </label> */}
                        <img 
                            src={process.env.PUBLIC_URL + "/Paniconfianca.png"}
                            style={{
                                aspectRatio : 3,
                                // width : 150,
                                height :  50 
                            }}
                        
                        />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className='d-print-none'/>
                <Navbar.Collapse id="basic-navbar-nav" >
                    <Nav className="me-auto">
                        <Nav.Link>
                            <Link to={Routing.PRODUCTS} style={{color : "white"}}>{ t("products") }</Link>
                        </Nav.Link>
                        <Nav.Link style={{ color: "white" }}>
                            <Link to={Routing.SCHEDULE} style={{color : "white"}}>{ t("schedule") }</Link>
                        </Nav.Link>
                        <Nav.Link style={{ color: "white" }}>
                            <Link to={Routing.LOCATION} style={{color : "white"}}>{ t("location") }</Link>
                        </Nav.Link>
                        <Nav.Link style={{ color: "white" }}>
                            <Link to={Routing.CONTACTS} style={{color : "white"}}>{ t("contacts") }</Link>
                        </Nav.Link>
                        <LanguageDropdown/>
                        <Container style={{
                            display : "flex", 
                            justifyContent : "space-between", 
                            alignItems : "center", 
                            width : 128
                        }}>
                            <a href={`tel:${contacts_data.phone}`}>
                                <FaPhone color='white' size={24}/>
                            </a>
                            <a href={`mailto:${contacts_data.email}`}>
                                <MdEmail color='white' size={24}/>
                            </a>
                            <a href={contacts_data.facebook}>
                                <FaFacebook color='white' size={24}/>
                            </a>
                        </Container>
                        {/* <Nav.Link style={{ color: "white" }}>
                            <Link to="/news">Notícias</Link>
                        </Nav.Link> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <div style={{paddingBottom : 150}}>
            <Outlet/>
        </div>
        <Footer/>
    </div>
}